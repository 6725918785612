
import http from '@/utils/http'
import { message, Modal } from 'ant-design-vue'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'

const columns = [
  {
    title: '域名',
    dataIndex: 'domain',
    key: 'domain',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    slots: { customRender: 'create_time' },
    align: 'center'
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
    key: 'update_time',
    slots: { customRender: 'update_time' },
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'status',
    slots: { customRender: 'status' },
    key: 'status',
    align: 'center'
  },
  {
    title: '权重',
    dataIndex: 'weight',
    slots: { customRender: 'weight' },
    key: 'weight',
    align: 'center'
  }
]

export default defineComponent({
  name: 'domain_list',
  setup () {
    const domain = ref('')
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const form = ref({ app_key: '' })

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/domains?page=${currentPage.value}`, {
        method: 'get',
         headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data
          total.value = dataSource.value.length
        }
      })
    }

    onMounted(() => {
      getList()
    })

    // 新增域名
    const add = () => {
      let value = domain.value
      let reg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      if (!reg.test(value)) {
        message.error('域名不合法！')
        return
      }
      http(`/tokenapi/domains`, {
        method: 'put',
        headers: {
          'x-token': sessionStorage.getItem('token')
        },
        body: JSON.stringify({
          domain: value
        })
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
          message.success(res.msg)
          domain.value = ''
        }
      })
    }

    // 切换状态
    const switchClick = (v: any) => {
      http('/tokenapi/domains', {
        method: 'post',
        headers: {
          'x-token': sessionStorage.getItem('token')
        },
        body: JSON.stringify({
          id: v.id,
          status: v.status === 1 ? 2 : 1
        })
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
          message.success('修改成功')
        }
      })
    }

    // 更新域名权重
    const updateWeight = (v: any) => {
      http('/tokenapi/domains', {
        method: 'post',
        headers: {
          'x-token': sessionStorage.getItem('token')
        },
        body: JSON.stringify({
          id: v.id,
          weight: v.weight
        })
      }).then((res: any) => {
        if (res.code === 200) {
          getList()
          message.success('修改成功')
        }
      })
    }

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      add,
      form,
      format_date,
      domain,
      switchClick,
      updateWeight
    }
  }
})
